import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入全局样式表
import './assets/css/global.css'

import axios from 'axios'


// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// require styles 导入富文本编辑器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)


import ElementUI from 'element-ui'  //引入element-ui库
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI)  //全局注册element-ui

// 配置请求的跟路径
Vue.prototype.$http = axios

//测试环境
// axios.defaults.baseURL = 'http://127.0.0.1:26601'


//正式环境
axios.defaults.baseURL = 'https://www.xiaodanyuan.com/admin'


axios.interceptors.request.use(config => {
  //当进入request拦截器，表示发送了请求，我们就开启进度条
  // NProgress.start()
  // console.log(config)
  config.headers.token = window.sessionStorage.getItem('token')
  // 在最后必须 return config
  return config
})






Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
