import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import('@/views/Login.vue')



const Home = () => import('@/views/Home.vue')

//用户
const User = () => import('@/views/user/User.vue')

const Comment = () => import('@/views/user/Comment.vue')

//分页
const Page = () => import('@/views/page/Pages.vue')
const PageEdit = () => import('@/views/page/PageEdit.vue')



//订单
const Order = () => import('@/views/order/Order.vue')
const ReturnOrder = () => import('@/views/order/ReturnOrder.vue')

//管理设置
const Company = () => import('@/views/admin/Company.vue')

const AdUser = () => import('@/views/admin/AdUser.vue')
const Role = () => import('@/views/admin/Role.vue')
const AdminCompany = () => import('@/views/admin/AdminCompany.vue')
const AdminPackage = () => import('@/views/admin/AdminPackage.vue')

const AdPackageCompany = () => import('@/views/admin/AdPackageCompany.vue')

const AdOperate = () => import('@/views/admin/AdOperate.vue')

const AdOperateUser = () => import('@/views/admin/AdOperateUser.vue')

const AdPolicy = () => import('@/views/admin/AdPolicy.vue')

const AdminUser = () => import('@/views/admin/AdminUser.vue')
const AdminRole = () => import('@/views/admin/AdminRole.vue')

const AdCompanyApply = () => import('@/views/admin/AdCompanyApply.vue')

const AdOrder = () => import('@/views/admin/AdOrder.vue')

const AdOrderReturn = () => import('@/views/admin/AdOrderReturn.vue')

const AdRecommend = () => import('@/views/admin/AdRecommend.vue')

const CompanyPay = () => import('@/views/admin/CompanyPay.vue')

const AdminCompanyPay = () => import('@/views/admin/AdminCompanyPay.vue')




//Ai
const Ai = () => import('@/views/ai/Ai.vue')

//促销
const Activity = () => import('@/views/award/Activity.vue')
const ActivityUser = () => import('@/views/award/ActivityUser.vue')

const AddActivity = () => import('@/views/award/AddActivity.vue')

const EditActivity = () => import('@/views/award/EditActivity.vue')
const Luck = () => import('@/views/award/Luck.vue')
const Ticket = () => import('@/views/award/Ticket.vue')
const TicketUser = () => import('@/views/award/TicketUser.vue')


//产品
const Ad = () => import('@/views/brand/Ad.vue')
const AddBrand = () => import('@/views/brand/AddBrand.vue')

const EditBrand = () => import('@/views/brand/EditBrand.vue')

const Brand = () => import('@/views/brand/Brand.vue')

const Article = () => import('@/views/brand/Article.vue')
const Category = () => import('@/views/brand/Category.vue')
const Service = () => import('@/views/brand/Service.vue')

const ServiceUser = () => import('@/views/brand/ServiceUser.vue')

const AddService = () => import('@/views/brand/AddService.vue')

const EditService = () => import('@/views/brand/EditService.vue')

const Cooperate = () => import('@/views/brand/Cooperate.vue')

const CooperateCompany = () => import('@/views/brand/CooperateCompany.vue')

const CooperateShow = () => import('@/views/brand/CooperateShow.vue')





const AdminHome = () => import('@/views/AdminHome.vue')


const CompanyHome = () => import('@/views/CompanyHome.vue')





Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/companyHome' },
  { path: '/login', component: Login },

  { path: '/companyHome', component: CompanyHome },

  {
    path: '/home', redirect: '/company', component: Home,
    children: [
      //用户
      { path: '/user', component: User },
      { path: '/comment', component: Comment },

      //页面
      { path: '/page', component: Page },
      { path: '/pageEdit', component: PageEdit },

      //订单
      { path: '/order', component: Order },
      { path: '/returnOrder', component: ReturnOrder },

      //设置
      { path: '/adUser', component: AdUser },
      { path: '/company', component: Company },
      { path: '/role', component: Role },

      { path: '/companyPay', component: CompanyPay },


      //ai
      { path: '/ai', component: Ai },

      //促销
      { path: '/activity', component: Activity },
      { path: '/activityUser', component: ActivityUser },
      { path: '/addActivity', component: AddActivity },
      { path: '/editActivity', component: EditActivity },
      { path: '/luck', component: Luck },
      { path: '/ticket', component: Ticket },
      { path: '/ticketUser', component: TicketUser },


      //产品
      { path: '/ad', component: Ad },
      { path: '/addBrand', component: AddBrand },
      { path: '/editBrand', component: EditBrand },
      { path: '/brand', component: Brand },
      { path: '/article', component: Article },
      { path: '/category', component: Category },
      { path: '/service', component: Service },
      { path: '/serviceUser', component: ServiceUser },
      { path: '/addService', component: AddService },
      { path: '/editService', component: EditService },

      { path: '/cooperate', component: Cooperate },
      { path: '/cooperateCompany', component: CooperateCompany },
      { path: '/cooperateShow', component: CooperateShow },


    ]
  },

  {
    path: '/adminHome', redirect: '/adminCompany', component: AdminHome,
    children: [
      //设置
      { path: '/adminCompany', component: AdminCompany },
      { path: '/adminPackage', component: AdminPackage },

      { path: '/adPackageCompany', component: AdPackageCompany },
      { path: '/adOperate', component: AdOperate },
      { path: '/adOperateUser', component: AdOperateUser },
      { path: '/adPolicy', component: AdPolicy },

      { path: '/adminUser', component: AdminUser },
      { path: '/adminRole', component: AdminRole },

      { path: '/adCompanyApply', component: AdCompanyApply },
      { path: '/adOrder', component: AdOrder },
      { path: '/adOrderReturn', component: AdOrderReturn },
      { path: '/adRecommend', component: AdRecommend },

      { path: '/adminCompanyPay', component: AdminCompanyPay },



    ]
  },



]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转
  //平台端管理登录
  // if (to.path === '/shopUserLogin/in') return next()

  //平台商家端登录

  // 获取token
  const tokenStr1 = JSON.stringify(window.sessionStorage.getItem('token'))
  const tokenStr2 = JSON.stringify(window.localStorage.getItem('token'))

  if (!tokenStr1) return next('/login')
  if (!tokenStr2) return next('/login')
  next()
})



export default router
